import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import arrow_left from "../../assets/images/arrow_left.png";
import Location_details_img from "../../assets/images/Location_details_img.png";
import Blog_details_img from "../../assets/images/Blog_details_img.png";
import defaultImage from "../../assets/images/Default_Load.png";
import moment from "moment";
import agent from "../../agent";
import Parser from "html-react-parser";
import { FETCH_BLOG } from "../../constants/actionTypes";
import { Helmet } from "react-helmet-async";
// import SEO from '../SEO/SEO';
const mapStateToProps = (state) => ({
  ...state,
  BlogData: state.common.BlogData,
});

const mapDispatchToProps = (dispatch) => ({
  onLoadBlog: async (bid) => {
    try {
      const response = await agent.common.getBlog(bid);
      const data = response; // Assuming the data is within the response object

      dispatch({
        type: FETCH_BLOG,
        payload: data,
      });
    } catch (error) {
      // Handle error here if needed
    }
  },
});

const Detail = (props) => {
  const { BlogData, onLoadBlog } = props;
  const [name, setName] = useState("");
  const [keyPoints, setKeyPoints] = useState("");
  const [image, setImage] = useState("");
  const [cdate, setCdate] = useState("");

  let params = useParams();
  useEffect(() => {
    if (params) {
      onLoadBlog(params.id);
    }
  }, []);

  useEffect(() => {
    if (BlogData) {
      setName(BlogData.title);
      setKeyPoints(BlogData.addContent);
      setImage(BlogData.bannerImage);
      setCdate(
        BlogData.updatedAt
          ? moment(BlogData.updatedAt).format("MMMM D, YYYY")
          : ""
      );
    }
  }, [BlogData]);

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        {BlogData?.pageTitle ? (
          <title>{BlogData?.pageTitle}</title>
        ) : (
          <title>Blog | Insights on Natural and Eco-Friendly Burials</title>
        )}
        {BlogData?.metaTags?.map((tag, index) => (
          <meta key={index} name={tag.metaName} content={tag.metaContent} />
        ))}
      </Helmet>
      <section id="Blog-details">
        <div className="container">
          <div className="Blog-details-head">
            <Link to="/blogs">
              <img src={arrow_left} alt="" /> Go back
            </Link>
            <span>{cdate}</span>
            <h1>{name}</h1>
            <img src={image} alt="" />
          </div>
          <>
            <div className="key-point">
              {/* <>
                {(keyPoints && keyPoints.length) > 0 ? (
                  <h3 style={{ fontWeight: "bold", marginBottom: "20px" }}>
                    Key Points:
                  </h3>
                ) : null}
              </> */}
              {(keyPoints && keyPoints.length) > 0
                ? keyPoints.map((keyPoint, i) => (
                    <div key={i} className="key-details">
                      {keyPoint.images !== "" && keyPoint.images !== null ? (
                        <div
                          className={`key-img${
                            i % 2 !== 0 ? " revert-img" : ""
                          }`}
                        >
                          <img src={keyPoint.images} alt="" />
                        </div>
                      ) : (
                        ""
                      )}
                      {keyPoint.images !== "" && keyPoint.images !== null ? (
                        <div
                          className={`key-text${
                            i % 2 !== 0 ? " revert-text" : ""
                          }`}
                        >
                          <p>{Parser(keyPoint.text)}</p>
                        </div>
                      ) : (
                        <div
                          className={`key-text-second${
                            i % 2 !== 0 ? " revert-text" : ""
                          }`}
                        >
                          <p>{Parser(keyPoint.text)}</p>`{" "}
                        </div>
                      )}
                    </div>
                  ))
                : null}
            </div>
          </>
        </div>
      </section>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
