import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import Blog_bg_img from "../../assets/images/Blog_bg_img.png";
import Blog_first from "../../assets/images/Blog_first.png";
import angleicon from "../../assets/images/angle-icon.png";
import defaultImg from "../../assets/images/default.jpg";
import agent from "../../agent";
import msgbox from "../../assets/images/msg-box.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";


import {
    FETCH_ALL_BLOGS,
} from "../../constants/actionTypes";
import Loader from '../components/Loader';
const mapStateToProps = (state) => ({
    ...state,
    allBlogs: state.common.allBlogs,
});

const mapDispatchToProps = (dispatch) => ({
    onLoadPage: async () => {
        try {
          const response = await agent.common.getAllBlogs();
          const data = response; 
          
          dispatch({
            type: FETCH_ALL_BLOGS,
            payload: data,
          });
        } catch (error) {
        }
    }
});

const MainView = (props) => {

    const { allBlogs, onLoadPage } = props
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch()
      useEffect(() => {
        onLoadPage();
        const fetchData = async () => {
            try {
                const response = await agent.common.getAllBlogs();
                const data = response;

                dispatch({
                    type: FETCH_ALL_BLOGS,
                    payload: data,
                });

                setIsLoading(false); 
            } catch (error) {
                setIsLoading(false); 
            }
        };

        fetchData();
    }, [onLoadPage]);


    useEffect(() => {
        if(allBlogs){
            setBlogs(allBlogs)
        }
	},[allBlogs])

    console.log("blogs" , blogs)

    
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1
                }
            },

            {
                breakpoint: 575,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
      <Fragment>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <section id="banner-img-main">
              <div className="custom-banner-img">
                <img src={Blog_bg_img} alt="banner-img" />
              </div>
              <div className="custom-banner-text">
                <div className="container">
                  <h1>Insights from Inspired Meadows</h1>
                </div>
              </div>
            </section>

            <section id="blog-main">
              <div className="container">
                <div class="blog-desk">
                  <div className="row">
                    <>
                      {blogs && blogs.length
                        ? blogs.map((blog, i) => (
                            <Fragment key={i}>
                              <div className="blogs-col">
                                <div className="blogs">
                                  <img
                                    src={
                                      blog.bannerImage && blog.bannerImage
                                        ? blog.bannerImage
                                        : defaultImg
                                    }
                                    alt="Blog image"
                                    onError={(e) => {
                                      e.target.src = defaultImg;
                                    }}
                                  />
                                  <span>
                                    {moment(blog.updatedAt).format(
                                      "MMMM D, YYYY"
                                    )}
                                  </span>
                                  <p>
                                    {blog.title.length > 65
                                      ? `${blog.title.substring(0, 65)}...`
                                      : blog.title}
                                  </p>
                                  <Link to={`/blog/${blog.customSlug}`}>
                                    Read Article{" "}
                                    <img src={angleicon} alt="arrow right" />{" "}
                                  </Link>
                                </div>
                              </div>
                            </Fragment>
                          ))
                        : null}
                    </>
                  </div>
                </div>
                <div class="blog-mobile">
                  <div className="row">
                    <div class="blog-slider">
                      <div className="slide">
                        <Slider {...settings}>
                          {blogs && blogs.length
                            ? blogs.map((blog, i) => (
                                <Fragment key={i}>
                                  <div>
                                    <div className="blogs-col">
                                      <div className="blogs">
                                        <img
                                          src={
                                            blog.bannerImage && blog.bannerImage
                                              ? blog.bannerImage
                                              : defaultImg
                                          }
                                          alt="Blog image"
                                          onError={(e) => {
                                            e.target.src = defaultImg;
                                          }}
                                        />
                                        <span>
                                          {moment(blog.updatedAt).format(
                                            "MMMM D, YYYY"
                                          )}
                                        </span>
                                        <p>
                                          {blog.title.length > 65
                                            ? `${blog.title.substring(
                                                0,
                                                65
                                              )}...`
                                            : blog.title}
                                        </p>
                                        <Link to={`/blog/${blog.customSlug}`}>
                                          Read Article{" "}
                                          <img
                                            src={angleicon}
                                            alt="arrow right"
                                          />{" "}
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </Fragment>
                              ))
                            : null}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="Adviser-main">
              <div className="future-planing overview-future-planing">
                <div className="container">
                  <div className="row">
                    <div className="talk-adviser-col">
                      <div className="talk-adviser">
                        <div className="msg-img">
                          <img src={msgbox} alt="message box" />
                        </div>
                        <div className="adviser-head">
                          <h3>Speak to an Advisor</h3>
                          <p>
                            For more information please contact us for a no
                            obligation call. Please click on SCHEDULE A CALL
                            button
                          </p>
                        </div>
                        <div className="adviser-contact">
                          <Link to="/schedule-a-call">
                            Schedule a Call <img src={anglerighticon} alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </Fragment>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MainView);